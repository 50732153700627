.footer-container{
    background-color : #022d36;
    padding:20px;
    text-align:center
}
.footer-row{
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    padding:5px 0
}
.footer-row2{
    display:flex;
    flex-wrap:wrap;
    justify-content:space-around;
    padding:10px 0;
    background-color:#003b5b
}
.footer-column{
    flex:1;
    /* max-width:300px; */
    margin:100px
}
.footer-logo{
    width:300px;
    height:50 px;
    margin-bottom:10px
}
.footer-text{
    font-weight:700;
    font-size:16px;
    margin-bottom:10px;
    color: white;
}
.footer-text2 {
    font-size: 16px;
    margin-bottom: 10px;
    color: white;
}
.footer-buttons{
    display:flex;
    justify-content:center;
    margin-bottom:10px
}
.footer-button{
    width:150px;
    height:60px
}
.footer-links{
    list-style-type:none;
    line-height:50px;
    padding:0;
    margin:0;
    text-align:left;
    font-size:large;
    font-weight:500;
    color:white;
}
.footer-links :hover{
    color:white;
    font-weight:700
}
.footer-links li{
    cursor: pointer;
    margin-bottom:3px;
}
.footer-links li:hover{
    color: #ff5e14;
    font-weight: 700;
}
.footer-social-icons{
    display:flex;
    justify-content:center;
    margin-top:10px;
    margin-right:85px
}
.footer-social-icon {
    width: 30px;
    height: 30px;
    margin: 0 5px;
    transition: transform 0.3s ease-in-out;
  }
  
  .footer-social-icon:hover {
    transform: scale(1.2); /* Adjust the scale factor as desired */
  }
  
.footer-widget-heading h3{
    color: white;
    font-size:20px;
    font-weight:600;
    margin-bottom:20px;
    margin-right:170px;
    position:relative
}
.footer-widget-heading h3::before{
    content:"";
    position:absolute;
    justify-content:center;
    bottom:-15px;
    height:2px;
    width:100px;
    background:#ff5e14
}
.footer-widget-followus h3{
    color:white;
    font-size:20px;
    font-weight:600;
    margin-bottom:50px;
    margin-right:150px;
    position:relative
}
.footer-widget-followus h3::before{
    content:"";
    position:absolute;
    justify-content:center;
    left:20;
    bottom:-15px;
    height:2px;
    width:100px;
    background:#ff5e14;
}
.footer-social-icon{
    width:30px;
    height:30px;
    margin:0 5px
}
.footer-links-row{
    display:flex;
    justify-content:center;
    margin-top:10px
}
.footer-links-row a{
    margin:0 10px;
    text-decoration:underline;
    font-weight:500;
    color:#fff;
    margin-bottom:10px
}
.footer-links-row a:hover{
    color:#ff5e14
}
.footer-text:last-child{
    margin-top:10px
}
.copyright-text p{
    margin:0;
    font-size:14px;
    color:#fff;
    margin-top:12px;
    margin-right:400px
}
.copyright-text p a{
    color:#ff5e14
}
