body {
  font-family: 'Montserrat', sans-serif;
  margin: 0; /* Reset default body margin */
}

.container-fluid {
  padding: 3% 15% 7%;
}

.heading {
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 3.5rem;
  line-height: 1.5;
  margin-top: 8%; /* Adjust the margin for spacing */
}

.download-button {
  margin-top: 5%; /* Adjust the top margin for spacing */
  margin-right: 3%; /* Adjust the right margin for spacing */
}

.iphone-img {
  transform: rotate(25deg);
  position: absolute;
  width: 300px;
  right: 13%; /* Adjust the right position */
  top: 50%; /* Center the image vertically */
  transform: translateY(-50%) rotate(25deg); /* Center vertically and apply rotation */
}
