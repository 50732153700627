*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: black;
    background-color: white;
}

.wrapper {
    margin: 150px auto;
    width: 70%;
  }
  
  .wrapper > div {
    flex-basis: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
  }
  
  .section_image{
    max-width: 320px;
    border: 3px solid white;
    border-radius: 10px;
    margin-right: 15px;
  }
  
  .text_box {
    color: black;
  }
  
  .text_box h2 {
    font-size: 42px;
  }
  
  .text_box p {
    font-size: 16px;
  }
  